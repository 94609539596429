<template>
    <AdminToast />
    <ConfirmDialog></ConfirmDialog>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-left-panel :data-item='dataItem'></project-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Proje Öğünler</h3>

					<p class='text-lg'>
                        Proje öğün fiyatlarını giriniz. Hizmet verilmeyen öğünler için 0 (sıfır) giriniz.<br>
                        <br>
                        Fiyat değişiklikleri yapıldıktan sonra ilk yapılan hakediş ve sonraki hesaplamalarda geçerli olur.<br>
                        <br>
                        Hakediş yapılmış günlerin fiyatlarının geriye dönük güncellenmesinde öncelikli olarak fiyatların değiştirilmesi gerekmektedir.<br>
                        <br>
                        Yapılmış hakediş günlerine girerek sayfanın en altında yer alan fiyat güncelle butonu ile toplu olarak fiyatlar güncellenir. Bu özellik dikkatli kullanılmalıdır.<br>
                        <br>
					</p>

				</Sidebar>

				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <ProjectTabMenu :project-id='projectId'></ProjectTabMenu>
                <div class='col-12'>


                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Kahvaltı</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='breakfastPrice' type='text' v-model='projectDefaultDataItem.breakfastPrice' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Öğle Yemeği</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='lunchPrice' type='text' v-model='projectDefaultDataItem.lunchPrice' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Akşam Yemeği</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='dinnerPrice' type='text' v-model='projectDefaultDataItem.dinnerPrice' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Gece Yemeği</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='nightMealPrice' type='text' v-model='projectDefaultDataItem.nightMealPrice' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Kumanya</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='snackPrice' type='text' v-model='projectDefaultDataItem.snackPrice' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>İftar</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='iftarPrice' type='text' v-model='projectDefaultDataItem.iftarPrice' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Sahur</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='sahurPrice' type='text' v-model='projectDefaultDataItem.sahurPrice' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0 font-bold'>Son Güncelleme</label>
                        <div class='col-12 md:col-10 text-red-500 font-bold'>
                            {{ formatDate(projectDefaultDataItem.insertDate) }}
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' class='mb-2' @click='saveConfirm'></Button>
                        </div>
                    </div>

                    <DataTable
                        :value='dataList'
                        :paginator='true'
                        class='p-datatable-gridlines mt-5'
                        :rows='5'
                        dataKey='id'
                        :rowHover='true'
                        filterDisplay='menu'
                        :loading='loadingIndicator'
                        responsiveLayout='scroll'
                    >


                        <template #empty>
                            Veri bulunamadı.
                        </template>

                        <template #loading>
                            Kayıtlar yükleniyor. Lütfen bekleyin.
                        </template>

                        <Column field='insertDate' header='Tarih' :showFilterMatchModes='false' style='width:15rem'>
                            <template #body='{data}'>
                                {{ formatDate(data.insertDate) }}
                            </template>
                        </Column>

                        <Column field='breakfastPrice' header='Kahvaltı' bodyClass='text-center' :showFilterMatchModes='false' style='width:7rem'>
                        </Column>

                        <Column field='lunchPrice' header='Öğle Yemeği' bodyClass='text-center' :showFilterMatchModes='false' style='width:7rem'>
                        </Column>

                        <Column field='dinnerPrice' header='Akşam Yemeği' bodyClass='text-center' :showFilterMatchModes='false' style='width:7rem'>
                        </Column>

                        <Column field='nightMealPrice' header='Gece Yemeği' bodyClass='text-center' :showFilterMatchModes='false' style='width:7rem'>
                        </Column>

                        <Column field='snackPrice' header='Kumanya' bodyClass='text-center' :showFilterMatchModes='false' style='width:7rem'>
                        </Column>

                        <Column field='iftarPrice' header='İftar' bodyClass='text-center' :showFilterMatchModes='false' style='width:7rem'>
                        </Column>

                        <Column field='sahurPrice' header='Sahur' bodyClass='text-center' :showFilterMatchModes='false' style='width:7rem'> 
                        </Column>
                      
                        <Column field='managerUser.name' header='Kullanıcı' :showFilterMatchModes='false'>
                            <template #body='{data}'>
                                {{ data.managerUser.name + ' ' + data.managerUser.surName }}
                            </template>
                        </Column>

                    </DataTable>
                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import ProjectService from '../../services/projectService';
import AdminToast from '@/components/shared/toast/adminToast';
import ProjectLeftPanel from './projectLeftPanel';
import ProjectTabMenu from './projectTabMenu';
import ProjectDefaultService from '../../services/projectDefaultService';
import ProjectDefaultHistoryService from '../../services/projectDefaultHistoryService';
import moment from 'moment';
import store from '@/store';
import { getProject } from '../common/commonFunctions';
import { checkActiveProjectIdIsValid } from '../common/commonConstantFunctions';

export default {
    components: { ProjectTabMenu, ProjectLeftPanel, AdminToast },
    _projectService: null,
    _projectDefaultService: null,
    _projectDefaultHistoryService: null,
    created() {
        this._projectService = new ProjectService();
        this._projectDefaultService = new ProjectDefaultService();
        this._projectDefaultHistoryService = new ProjectDefaultHistoryService();
        this.projectId = this.$route.params.projectId;

    },
    async mounted() {
        checkActiveProjectIdIsValid(this, this.projectId);
        this.loadingIndicator = true;
        this.dataItem = await getProject(this.projectId);
        await this.getProjectDefaults();
        await this.getProjectDefaultHistories();
        this.loadingIndicator = false;
    },
    data() {
        return {
            dataList: [],
            projectId: 0,
            loadingIndicator: false,
            projectDefaultDataItem: {
                isBreakfastVisible: '',
                isLunchVisible: '',
                isDinnerVisible: '',
                isSnackVisible: '',
                isIftarVisible: '',
                isSahurVisible: '',
                isNightMealVisible: '',
                nightMealPrice: '',
                isBreakfastOrderVisible: '',
                isLunchOrderVisible: '',
                isDinnerOrderVisible: '',
                isIftarOrderVisible: '',
                isSnackOrderVisible: '',
                isSahurOrderVisible: '',
                isNightMealOrderVisible: '',
            },
            dataItem:{},
            display: false,
            visibleLeft: false,
        };
    },
    methods: {

        async saveConfirm() {
            this.$confirm.require({
                message: 'Varsayılan Fiyatların Güncellenmesini İstediğinizden Emin Misiniz? Güncelleme Geri Alınamaz.',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    await this.save();
                },
            });
        },
        async getProjectDefaultHistories() {
            let historyResponse = await this._projectDefaultHistoryService.getProjectHistories(this.projectId);
            if (historyResponse.isSuccess) {
                this.dataList = historyResponse.data;
            }
        },
        async getProjectDefaults() {
            let projectDefaultResponse = await this._projectDefaultService.getProjectDefaultsById(this.projectId);
            if (projectDefaultResponse.isSuccess) {
                this.projectDefaultDataItem.breakfastPrice = projectDefaultResponse.data.breakfastPrice;
                this.projectDefaultDataItem.lunchPrice = projectDefaultResponse.data.lunchPrice;
                this.projectDefaultDataItem.dinnerPrice = projectDefaultResponse.data.dinnerPrice;
                this.projectDefaultDataItem.snackPrice = projectDefaultResponse.data.snackPrice;
                this.projectDefaultDataItem.sahurPrice = projectDefaultResponse.data.sahurPrice;
                this.projectDefaultDataItem.iftarPrice = projectDefaultResponse.data.iftarPrice;
                this.projectDefaultDataItem.projectId = projectDefaultResponse.data.projectId;
                this.projectDefaultDataItem.insertDate = projectDefaultResponse.data.insertDate;
                this.projectDefaultDataItem.nightMealPrice = projectDefaultResponse.data.nightMealPrice;
                this.projectDefaultDataItem.isBreakfastVisible = projectDefaultResponse.data.isBreakfastVisible;
                this.projectDefaultDataItem.isLunchVisible = projectDefaultResponse.data.isLunchVisible;
                this.projectDefaultDataItem.isDinnerVisible = projectDefaultResponse.data.isDinnerVisible;
                this.projectDefaultDataItem.isSnackVisible = projectDefaultResponse.data.isSnackVisible;
                this.projectDefaultDataItem.isIftarVisible = projectDefaultResponse.data.isIftarVisible;
                this.projectDefaultDataItem.isSahurVisible = projectDefaultResponse.data.isSahurVisible;
                this.projectDefaultDataItem.isNightMealVisible = projectDefaultResponse.data.isNightMealVisible;
                this.projectDefaultDataItem.isBreakfastOrderVisible = projectDefaultResponse.data.isBreakfastOrderVisible;
                this.projectDefaultDataItem.isLunchOrderVisible = projectDefaultResponse.data.isLunchOrderVisible;
                this.projectDefaultDataItem.isDinnerOrderVisible = projectDefaultResponse.data.isDinnerOrderVisible;
                this.projectDefaultDataItem.isIftarOrderVisible = projectDefaultResponse.data.isIftarOrderVisible;
                this.projectDefaultDataItem.isSnackOrderVisible = projectDefaultResponse.data.isSnackOrderVisible;
                this.projectDefaultDataItem.isSahurOrderVisible = projectDefaultResponse.data.isSahurOrderVisible;
                this.projectDefaultDataItem.isNightMealOrderVisible = projectDefaultResponse.data.isNightMealOrderVisible;
            }
        },
        async save() {

            if (this.validateForm()) {
                if (this.projectDefaultDataItem.projectId == null || this.projectDefaultDataItem.projectId == 0) {
                    this.projectDefaultDataItem.projectId = this.projectId;
                    this.projectDefaultDataItem.isActive = true;

                    let insertResponse = await this._projectDefaultService.insertProjectDefaults(this.projectDefaultDataItem);
                    if (insertResponse.isSuccess) {
                        await this.getProjectDefaultHistories();
                        showSuccessMessage(this, 'Fiyat Ekleme Başarılı');
                    } else {
                        showErrorMessage(this, 'Fiyat Eklemede Sorun ile Karşılaşıldı');
                    }
                    return;
                }
                let userId = store.getters.getUserId;
                this.projectDefaultDataItem.managerUserId = userId;
                await this._projectDefaultHistoryService.insertProjectDefaultHistory(this.projectDefaultDataItem);
                const updateResponse = await this._projectDefaultService.updateProjectDefault(this.projectId, this.projectDefaultDataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, 'Fiyat Güncelleme Başarılı');
                    await this.getProjectDefaultHistories();
                } else {
                    showErrorMessage(this, 'Fiyat Güncellemede Sorun İle Karşılaşıldı');
                }
            }

        },

        validateForm() {
            if (this.projectDefaultDataItem.breakfastPrice == null || this.projectDefaultDataItem.breakfastPrice === '') {
                showValidationMessage(this, 'Kahvaltı Fiyatı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.projectDefaultDataItem.lunchPrice == null || this.projectDefaultDataItem.lunchPrice === '') {
                showValidationMessage(this, 'Öğle Yemeği Fiyatı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.projectDefaultDataItem.dinnerPrice == null || this.projectDefaultDataItem.dinnerPrice === '') {
                showValidationMessage(this, 'Akşam Yemeği Fiyatı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.projectDefaultDataItem.nightMealPrice == null || this.projectDefaultDataItem.nightMealPrice === '') {
                showValidationMessage(this, 'Gece Yemeği Fiyatı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.projectDefaultDataItem.snackPrice == null || this.projectDefaultDataItem.snackPrice === '') {
                showValidationMessage(this, 'Kumanya Fiyatı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.projectDefaultDataItem.iftarPrice == null || this.projectDefaultDataItem.iftarPrice === '') {
                showValidationMessage(this, 'İftar Fiyatı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.projectDefaultDataItem.sahurPrice == null || this.projectDefaultDataItem.sahurPrice === '') {
                showValidationMessage(this, 'Sahur Fiyatı Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },

        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:mm');
        },
    },
};

</script>


<style scoped>

</style>
