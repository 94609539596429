import {
    getData,
    insertData,
    patchRawData,
} from './commonService';
import moment from 'moment';

export default class ProjectDefaultService {


    #defaultUrl = "/api/Project";
    #defaultResourceName = "Proje";

    async getProjectDefaultsById(projectId) {
        return await getData(this.#defaultUrl + "/" + projectId + "/default");
    }

    async insertProjectDefaults(dataItem) {
        const createData = {
            "projectId": dataItem.projectId,
            "breakfastPrice": dataItem.breakfastPrice,
            "lunchPrice": dataItem.lunchPrice,
            "dinnerPrice": dataItem.dinnerPrice,
            "snackPrice": dataItem.snackPrice,
            "iftarPrice": dataItem.iftarPrice,
            "sahurPrice": dataItem.sahurPrice,
            "managerUserId": dataItem.managerUserId,
            "nightMealPrice": dataItem.nightMealPrice,
            "isBreakfastVisible": dataItem.isBreakfastVisible,
            "isLunchVisible": dataItem.isLunchVisible,
            "isDinnerVisible": dataItem.isDinnerVisible,
            "isSnackVisible": dataItem.isSnackVisible,
            "isIftarVisible": dataItem.isIftarVisible,
            "isSahurVisible": dataItem.isSahurVisible,
            "isNightMealVisible": dataItem.isNightMealVisible,
            "isBreakfastOrderVisible": dataItem.isBreakfastOrderVisible,
            "isLunchOrderVisible": dataItem.isLunchOrderVisible,
            "isDinnerOrderVisible": dataItem.isDinnerOrderVisible,
            "isIftarOrderVisible": dataItem.isIftarOrderVisible,
            "isSnackOrderVisible": dataItem.isSnackOrderVisible,
            "isSahurOrderVisible": dataItem.isSahurOrderVisible,
            "isNightMealOrderVisible": dataItem.isNightMealOrderVisible,
            "isActive": true,
        }

        return await insertData(this.#defaultUrl + "/" + dataItem.projectId + "/default", createData, this.#defaultResourceName);
    }

    async updateProjectDefault(projectId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "projectId", "value": dataItem.projectId},
            {"op": "replace", "path": "breakfastPrice", "value": dataItem.breakfastPrice},
            {"op": "replace", "path": "lunchPrice", "value": dataItem.lunchPrice},
            {"op": "replace", "path": "dinnerPrice", "value": dataItem.dinnerPrice},
            {"op": "replace", "path": "snackPrice", "value": dataItem.snackPrice},
            {"op": "replace", "path": "iftarPrice", "value": dataItem.iftarPrice},
            {"op": "replace", "path": "sahurPrice", "value": dataItem.sahurPrice},
            {"op": "replace", "path": "nightMealPrice", "value": dataItem.nightMealPrice},
            {"op": "replace", "path": "insertDate", "value": moment().add(3, 'hour')  .toDate()},
        ];


        return await patchRawData(this.#defaultUrl + "/" + projectId + "/default", patchDocument, this.#defaultResourceName);
    }

    async updateProjectVisibleSettings(projectId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "isBreakfastVisible", "value": dataItem.isBreakfastVisible},
            {"op": "replace", "path": "isLunchVisible", "value": dataItem.isLunchVisible},
            {"op": "replace", "path": "isDinnerVisible", "value": dataItem.isDinnerVisible},
            {"op": "replace", "path": "isSnackVisible", "value": dataItem.isSnackVisible},
            {"op": "replace", "path": "isIftarVisible", "value": dataItem.isIftarVisible},
            {"op": "replace", "path": "isSahurVisible", "value": dataItem.isSahurVisible},
            {"op": "replace", "path": "isNightMealVisible", "value": dataItem.isNightMealVisible},
            {"op": "replace", "path": "isBreakfastOrderVisible", "value": dataItem.isBreakfastOrderVisible},
            {"op": "replace", "path": "isLunchOrderVisible", "value": dataItem.isLunchOrderVisible},
            {"op": "replace", "path": "isDinnerOrderVisible", "value": dataItem.isDinnerOrderVisible},
            {"op": "replace", "path": "isIftarOrderVisible", "value": dataItem.isIftarOrderVisible},
            {"op": "replace", "path": "isSnackOrderVisible", "value": dataItem.isSnackOrderVisible},
            {"op": "replace", "path": "isSahurOrderVisible", "value": dataItem.isSahurOrderVisible},
            {"op": "replace", "path": "isNightMealOrderVisible", "value": dataItem.isNightMealOrderVisible},
        ];


        return await patchRawData(this.#defaultUrl + "/" + projectId + "/default", patchDocument, this.#defaultResourceName);
    }








}