import {
    getData,
    insertData,
} from './commonService';

export default class ProjectDefaultHistoryService {


    #defaultUrl = "/api/Project";
    #defaultResourceName = "Proje";

    async insertProjectDefaultHistory(dataItem) {
        const createData = {
            "projectId": dataItem.projectId,
            "breakfastPrice": dataItem.breakfastPrice,
            "lunchPrice": dataItem.lunchPrice,
            "dinnerPrice": dataItem.dinnerPrice,
            "snackPrice": dataItem.snackPrice,
            "iftarPrice": dataItem.iftarPrice,
            "sahurPrice": dataItem.sahurPrice,
            "nightMealPrice": dataItem.nightMealPrice,
            "isActive": true,
            "managerUserId": dataItem.managerUserId,
        }

        return await insertData(this.#defaultUrl + "/" + dataItem.projectId + "/defaultHistory", createData, this.#defaultResourceName);
    }

    async getProjectHistories(projectId) {
        return await getData(this.#defaultUrl + "/" +projectId + "/defaultHistory")
    }
}